import {
    elToggleClasses,
} from "../utils/element-helpers";

/**
 * Intro Hero
 * 
 * Basic timeouts to control states then enable main content
 */
const introHero = () => {
    const htmlEl = document.querySelector("html");
    const bannerEl = document.querySelector("[data-intro-hero]");

    setTimeout(() => {
        elToggleClasses(bannerEl, { "ani-show-title": true });

        setTimeout(() => {
            elToggleClasses(bannerEl, { "ani-show-plaster": true });

            setTimeout(() => {
                elToggleClasses(htmlEl, {
                    "initiating": false,
                    "init": true,
                });

                setTimeout(() => {
                    elToggleClasses(htmlEl, {
                        "initiated": true,
                    });
                }, 2000);
            }, 500);
        }, 1000);
    }, 100);
}

document.addEventListener("DOMContentLoaded", introHero);

export default introHero;
