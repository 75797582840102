import {gsap} from 'gsap';
const map = (x, a, b, c, d) => (x - a) * (d - c) / (b - a) + c;
const lerp = (a, b, n) => (1 - n) * a + n * b;
const clamp = (num, min, max) => num <= min ? min : num >= max ? max : num;
const body = document.body
const listWrapper = document.querySelector('.section-block.section-block--textures-list');

const getMousePos = (e) => {
    let posx = 0;
    let posy = 0;
    posx = e.clientX + body.scrollLeft + document.documentElement.scrollLeft;
    posy = e.clientY + body.scrollTop + listWrapper.scrollTop;
    return { x : posx, y : posy }
};

let mousepos = {x: 0, y: 0};
let mousePosCache = mousepos;
let direction = {x: mousePosCache.x-mousepos.x, y: mousePosCache.y-mousepos.y};

listWrapper?.addEventListener('mousemove', ev => mousepos = getMousePos(ev));

class MenuItem {
    constructor(el, inMenuPosition, animatableProperties, totalItems) {
        this.DOM = {el: el};
        this.totalItems = totalItems;
        this.inMenuPosition = inMenuPosition;
        this.animatableProperties = animatableProperties;
        this.layout();
        this.initEvents();
    }
    layout() {
        this.DOM.reveal = document.createElement('div');
        this.DOM.reveal.className = 'hover-reveal';
        this.DOM.reveal.style.transformOrigin = '0% 0%';
        this.DOM.revealInner = document.createElement('div');
        this.DOM.revealInner.className = 'hover-reveal__inner';
        this.DOM.revealImage = document.createElement('div');
        this.DOM.revealImage.className = 'hover-reveal__img';
        this.DOM.revealImage.style.backgroundImage = `url(${this.DOM.el.dataset.img})`;
        this.DOM.revealInner.appendChild(this.DOM.revealImage);
        this.DOM.reveal.appendChild(this.DOM.revealInner);
        this.DOM.el.appendChild(this.DOM.reveal);
    }
    calcBounds() {
        this.bounds = {
            el: this.DOM.el.getBoundingClientRect(),
            reveal: this.DOM.reveal.getBoundingClientRect()
        };
    }
    initEvents() {
        this.mouseenterFn = (ev) => {
            this.showImage();
            this.firstRAFCycle = true;
            this.loopRender();
        };
        this.mouseleaveFn = () => {
            this.stopRendering();
            this.hideImage();
        };
        
        this.DOM.el.addEventListener('mouseenter', this.mouseenterFn);
        this.DOM.el.addEventListener('mouseleave', this.mouseleaveFn);
    }
    showImage() {
        gsap.killTweensOf(this.DOM.revealInner);
        gsap.killTweensOf(this.DOM.revealImage);
        
        this.tl = gsap.timeline({
            onStart: () => {
                this.DOM.reveal.style.opacity = this.DOM.revealInner.style.opacity = 1;
                gsap.set(this.DOM.el, {zIndex: this.totalItems});
            }
        })
        .to(this.DOM.revealInner, 1, {
            ease: 'Expo.easeOut',
            startAt: {scale: 0.8},
            scale: 1
        })
        .to(this.DOM.revealImage, 1, {
            ease: 'Expo.easeOut',
            startAt: {scale: 1.7},
            scale: 1
        }, 0);
    }
    hideImage() {
        gsap.killTweensOf(this.DOM.revealInner);
        gsap.killTweensOf(this.DOM.revealImage);

        this.tl = gsap.timeline({
            onStart: () => {
                gsap.set(this.DOM.el, {zIndex: 1});
            },
            onComplete: () => {
                gsap.set(this.DOM.reveal, {opacity: 0});
            }
        })
        .to(this.DOM.revealInner,0.5, {
            ease: 'Expo.easeOut',
            scale: 0.8,
            opacity: 0
        })
        .to(this.DOM.revealImage,0.5, {
            ease: 'Expo.easeOut',
            scale: 1.7
        },0);
    }
    loopRender() {
        if ( !this.requestId ) {
            this.requestId = requestAnimationFrame(() => this.render());
        }
    }
    stopRendering() {
        if ( this.requestId ) {
            window.cancelAnimationFrame(this.requestId);
            this.requestId = undefined;
        }
    }
    render() {
        this.requestId = undefined;
        if ( this.firstRAFCycle ) {
            this.calcBounds();
        }
        const mouseDistanceX = clamp(Math.abs(mousePosCache.x - mousepos.x), 0, 100);
        direction = {x: mousePosCache.x-mousepos.x, y: mousePosCache.y-mousepos.y};
        mousePosCache = {x: mousepos.x, y: mousepos.y};

        this.animatableProperties.tx.current = Math.abs(mousepos.x - this.bounds.el.left) - this.bounds.reveal.width/2;
        this.animatableProperties.ty.current = Math.abs(mousepos.y - this.bounds.el.top) - this.bounds.reveal.height/2;
        this.animatableProperties.rotation.current = this.firstRAFCycle ? 0 : map(mouseDistanceX,0,250,0,direction.x < 0 ? 60 : -60);
        this.animatableProperties.brightness.current = this.firstRAFCycle ? 1 : map(mouseDistanceX,0,100,1,3);

        this.animatableProperties.tx.previous = this.firstRAFCycle ? this.animatableProperties.tx.current : lerp(this.animatableProperties.tx.previous, this.animatableProperties.tx.current, this.animatableProperties.tx.amt);
        this.animatableProperties.ty.previous = this.firstRAFCycle ? this.animatableProperties.ty.current : lerp(this.animatableProperties.ty.previous, this.animatableProperties.ty.current, this.animatableProperties.ty.amt);
        this.animatableProperties.rotation.previous = this.firstRAFCycle ? this.animatableProperties.rotation.current : lerp(this.animatableProperties.rotation.previous, this.animatableProperties.rotation.current, this.animatableProperties.rotation.amt);
        this.animatableProperties.brightness.previous = this.firstRAFCycle ? this.animatableProperties.brightness.current : lerp(this.animatableProperties.brightness.previous, this.animatableProperties.brightness.current, this.animatableProperties.brightness.amt);


        gsap.set(this.DOM.reveal, {
            x: this.animatableProperties.tx.previous,
            // y: this.animatableProperties.ty.previous,
            y: this.animatableProperties.ty.previous,
            rotation: this.animatableProperties.rotation.previous,
            // filter: `brightness(${this.animatableProperties.brightness.previous})`
        });

        this.firstRAFCycle = false;
        this.loopRender();
    }
}
export default class Menu {
    constructor(el) {
        this.DOM = {el: el};
        this.DOM.menuItems = this.DOM.el.querySelectorAll('.textures-list__item');
        this.animatableProperties = {
            tx: {previous: 0, current: 0, amt: 0.08},
            ty: {previous: 0, current: 0, amt: 0.08},
            rotation: {previous: 0, current: 0, amt: 0.04},
            brightness: {previous: 1, current: 1, amt: 0.08},
        };
        this.totalItems = this.DOM.menuItems.length;
        this.menuItems = [];
        [...this.DOM.menuItems].forEach((item, pos) => this.menuItems.push(new MenuItem(item, pos, this.animatableProperties, this.totalItems )));
    }
}


const textureListEl = document.querySelector('.textures-list__list');

if(textureListEl){
    new Menu(textureListEl);
}

