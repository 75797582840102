// Vendor
import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
Alpine.plugin(focus);
window.Alpine = Alpine;
window.Alpine.start();

import "lazysizes/plugins/respimg/ls.respimg";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "lazysizes";
import "lazysizes/plugins/object-fit/ls.object-fit";

import { Fancybox } from "@fancyapps/ui";
import { gsap } from "gsap";

// Modules
import "./modules/splide"
import "./modules/accordion"
import "./modules/el-copy"
import "./modules/el-query"
import "./modules/filters"
import "./modules/formie"
import "./modules/image-zoom"
import "./modules/intro-hero"
import "./modules/scroller"
import "./modules/service-sections"
import "./modules/scroll-to"
import "./modules/svg-icon-sprite"
import "./modules/table-of-contents"
import "./modules/texture-sections"
import "./modules/video-fit"
import "./modules/patches"
import "./modules/listhoverImage"

history.scrollRestoration = "manual";
window.onbeforeunload = () => {
	window.scrollTo(0,0);
}

document.addEventListener("DOMContentLoaded", () => {
  const htmlEl = document.querySelector("html");
  const cursor = document.getElementById("cursor");
  const cursorBallBig = document.querySelector(".circle-big");

  let posS = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
  let posB = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
  let mouse = { x: posS.x, y: posS.y };
  const speed = 0.5;
  let fpms = 60 / 1000;
  let isCursorVisible = false;

  // Initially hide the cursor
  cursor.style.opacity = 0;

  // Function to show the cursor on first mouse move
  const showCursorOnce = () => {
    if (!isCursorVisible) {
      gsap.to(cursor, { opacity: 1, duration: 0.2 });
      isCursorVisible = true; // Ensure it only shows once
    }
  };

  window.addEventListener("mousemove", (e) => {
    mouse.x = e.x;
    mouse.y = e.y;

    showCursorOnce();
  });

  const xSetBallBig = gsap.quickSetter(cursorBallBig, "x", "px");
  const ySetBallBig = gsap.quickSetter(cursorBallBig, "y", "px");

  gsap.ticker.add((time, deltaTime) => {
    let delta = deltaTime * fpms;
    let dt = 1.0 - Math.pow(1.0 - speed, delta);

    posS.x += mouse.x - posS.x;
    posS.y += mouse.y - posS.y;
    posB.x += (mouse.x - posB.x) * dt;
    posB.y += (mouse.y - posB.y) * dt;

    xSetBallBig(posB.x);
    ySetBallBig(posB.y);
  });

  // Add hover effect to scale cursor up
  document
    .querySelectorAll("a, button, .splide__pagination__page")
    .forEach((element) => {
      element.addEventListener("mouseenter", () => {

        gsap.to(cursorBallBig, {
          scale: 2.5,
          duration: 0.3,
          ease: "power2.out"
        });
      });

      element.addEventListener("mouseleave", () => {
        gsap.to(cursorBallBig, {
          scale: 1,
          duration: 0.3,
          ease: "power2.out"
        });
      });
    });
});
